import React from 'react';
import {Box, Stack} from '@mui/material';
import {
  Body,
  Headline,
  Icon,
  designSystemToken,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';

interface IconWithBackgroundProps {
  iconName: IconName;
  style?: React.CSSProperties;
}

function IconWithBackground({iconName, style}: IconWithBackgroundProps) {
  return (
    <Box
      width="40px"
      height="40px"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="8px"
      border={`1px solid ${designSystemToken('semantic.bg.tertiary')}`}
      sx={{
        backgroundColor: designSystemToken('semantic.bg.alpha-secondary'),
        ...style,
      }}
    >
      <Icon
        name={iconName}
        size="medium"
        appearance="neutral"
        color={designSystemToken('semantic.fg.white')}
      />
    </Box>
  );
}

export interface FeatureCardProps {
  title: string;
  description: string;
  iconName: IconName;
  onClick?: () => void;
}

function FeatureCard(props: FeatureCardProps) {
  const {title, description, iconName, onClick} = props;
  const borderRadius = '16px';

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="375px"
      borderRadius={borderRadius}
      border={`1px solid ${designSystemToken('semantic.bg.tertiary')}`}
      sx={{
        background: designSystemToken('semantic.bg.secondary'),
        boxShadow:
          '0px 0px 1px 0px rgba(14, 21, 38, 0.08), 0px 2px 32px 0px rgba(14, 21, 38, 0.06)',
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <Box
        position="relative"
        sx={{
          borderRadius: `${borderRadius} ${borderRadius} 0 0`,
          overflow: 'hidden',
        }}
      >
        <IconWithBackground
          iconName={iconName}
          style={{position: 'absolute', top: '10px', left: '10px', zIndex: 1}}
        />
        <Box
          component="img"
          src="assets/images/content-studio/checker.png"
          alt=""
          width="100%"
          height="211px"
          sx={{
            objectFit: 'cover',
          }}
        />
      </Box>
      <Stack direction="column" gap="8px" padding="12px" width="100%">
        <Headline
          size="sm"
          color={designSystemToken('semantic.fg.primary')}
          sx={{textAlign: 'center'}}
        >
          {title}
        </Headline>
        <Body
          size="sm"
          color={designSystemToken('semantic.fg.secondary')}
          sx={{textAlign: 'center'}}
        >
          {description}
        </Body>
      </Stack>
    </Box>
  );
}

export default FeatureCard;
