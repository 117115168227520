import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Stack} from '@mui/material';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import FeatureCard from './components/feature-card';
import Header from './components/header';

type Feature = {
  title: string;
  description: string;
  iconName: IconName;
  path: string;
};

function FeaturesSection({features}: {features: Feature[]}) {
  const navigate = useNavigate();

  return (
    <Stack alignItems="center" width="100%" position="relative">
      <Stack
        direction="row"
        gap="16px"
        py="16px"
        maxWidth="100%"
        sx={{
          'overflowX': 'auto',
          '&::-webkit-scrollbar': {
            display: 'none' /* Chrome, Safari, Opera */,
          },
          '& > *': {
            flex: '0 0 auto' /* Prevent items from shrinking */,
          },
        }}
      >
        {features.map((feature) => (
          <FeatureCard
            key={feature.title}
            title={translate(feature.title)}
            description={translate(feature.description)}
            iconName={feature.iconName}
            onClick={() => navigate(feature.path)}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function ContentStudio() {
  const features: Feature[] = [
    {
      title: 'Scale to any platform',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      iconName: 'Actions-Expand',
      path: '/content-studio/reformatting',
    },
    {
      title: 'Dub to any language',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      iconName: 'Features-CoolDown',
      path: '/content-studio/dubbing',
    },
    {
      title: 'Rephrase ad transcript',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      iconName: 'Actions-Reset',
      path: '/content-studio/rephrasing',
    },
  ];

  return (
    <Stack
      direction="column"
      width="100%"
      alignItems="center"
      height="100vh"
      overflow="hidden"
    >
      <Header />
      <FeaturesSection features={features} />
    </Stack>
  );
}

export default ContentStudio;
